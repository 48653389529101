import { Link } from 'react-router-dom'
import reactTutorials from '../reactTutorials'
const  React_VideoCouList= ({reactTutorials}) => {
    return (  
        <ul className="app-menu">
            {reactTutorials.map((cou) =>( 
            <li  key={cou._id}>
                <a class="app-menu__item" href={`/react/${cou._id}`}>
                <span className='lecture-icon'>
                <i className='fa fa-youtube-play'></i>
                </span>&nbsp;<span class="app-menu__label">{cou.description}&nbsp;({cou.time})</span>
                </a>                      
            </li> 
            ))}
     </ul>
    );
}
 
export default React_VideoCouList;