import { BrowserRouter as Router,Routes,Route} from 'react-router-dom'
import Footer from './components/Footer';
import HomeScreen from './screens/HomeScreen'
import PythonScreen from './screens/PythonScreen'
import ReactScreen from './screens/ReactScreen';
import VideoAdmin from './screens/VideoAdmin'
import React_VideoAdmin from './screens/React_VideoAdmin'
function App() { 
  return (
    <Router>
     
      <main>
          <Routes>
            <Route exact={true} path="/" element={<HomeScreen />} />
            <Route  path="/learn-python" element={<PythonScreen />} />
            <Route  path="/python-begineers/:id" element={<VideoAdmin />} />
            <Route  path="/react/:id" element={<React_VideoAdmin />} />
            <Route path="/learn-react" element={<ReactScreen/>}/>
          </Routes>  
      </main>
    </Router>

  );
}

export default App;
