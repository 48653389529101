import React from 'react'
import { Container} from 'react-bootstrap'

function Headers() {
  return (
    // https://github.com/iamshaunjp/Complete-React-Tutorial/blob/lesson-6/dojo-blog/src/index.css
   <header>
    <nav className="navbar navbar-expand-lg bg-dark text-uppercase fixed-top" id="mainNav">
        <Container>
        <a className="navbar-brand js-scroll-trigger" href="/">Mukasa-Willy</a>
        <button className="navbar-toggler navbar-toggler-right text-uppercase font-weight-bold bg-primary text-white rounded" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          Menu
        <i className="fa fa-bars"></i>
      </button>

      <div className="collapse navbar-collapse" id="navbarResponsive">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item mx-0 mx-lg-1">
            <a className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" href="#courses">COURSES</a>
          </li>
           <li className="nav-item mx-0 mx-lg-1">
            <a className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" href="#tutorials">TUTORIALS</a>
          </li> 
          <li className="nav-item mx-0 mx-lg-1">
            <a className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" href="#about">ABOUT</a>
          </li>
          <li className="nav-item mx-0 mx-lg-1">
            <a className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" href="/pythonScreen.js">COACHING</a>
          </li>
          <li className="nav-item mx-0 mx-lg-1">
            <a className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" href="/services">SERVICES</a>
          </li>
          <li className="nav-item mx-0 mx-lg-1">
            <a className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" href="/login">LOGIN</a>
          </li>
        </ul>
      </div>
        </Container>
    </nav>
    </header>
  )
}

export default Headers