import {Link} from 'react-router-dom'
import Headers from '../components/Headers';
import Footer from '../components/Footer';
const home = () => {
    return ( 
       <div id="page-top">
           {/* https://fontawesome.com/v4/icon/download */}
            <Headers/>
        <header className="masthead bg-secondary  text-white text-center">
          <div className="container d-flex align-items-center flex-column">
            <p className="masthead-subheading font-weight-light mb-0">A better learning Python starts here, do u need one-on-one assistance? Am available for coaching</p>
          </div>
        </header>

        <section className="page-section portfolio" id="courses">
            <div className="container">

            <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">COURSES</h2>

            <div className="divider-custom">
                <div className="divider-custom-line"></div>
            </div>
 
            <div className="row">
              {/*
                <div className="col-md-6 col-lg-4">
                <div className="portfolio-item mx-auto" data-toggle="modal" data-target="#portfolioModal1">
                    <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                    <div className="portfolio-item-caption-content text-center text-white">
                        <i className="fas fa-plus fa-3x"></i>
                    </div>
                    </div>
                    <img className="img-fluid" src="img/portfolio/cabin.png" alt=""/>
                </div>
                </div>

                
                 <div className="col-md-6 col-lg-4">
                <div className="portfolio-item mx-auto" data-toggle="modal" data-target="#portfolioModal2">
                    <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                    <div className="portfolio-item-caption-content text-center text-white">
                        <i className="fas fa-plus fa-3x"></i>
                    </div>
                    </div>
                    <img className="img-fluid" src="img/portfolio/cake.png" alt=""/>
                </div>
                </div>

                
                <div className="col-md-6 col-lg-4">
                <div className="portfolio-item mx-auto" data-toggle="modal" data-target="#portfolioModal3">
                    <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                    <div className="portfolio-item-caption-content text-center text-white">
                        <i className="fas fa-plus fa-3x"></i>
                    </div>
                    </div>
                    <img className="img-fluid" src="img/portfolio/circus.png" alt=""/>
                </div>
                </div> */}

                
                <div className="col-md-6 col-lg-4">
                <div className="portfolio-item mx-auto" data-toggle="modal" data-target="#portfolioModal4">
                    {/* <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                    <div className="portfolio-item-caption-content text-center text-white">
                        <i className="fas fa-plus fa-3x"></i>
                    </div>
                    </div> */}
                    <Link to='/learn-react'>
                        <img className="img-fluid" src={process.env.PUBLIC_URL+"img/reactjs.png"} alt=""/>
                    </Link>
                    
                </div>
                </div>

                
                <div className="col-md-6 col-lg-4">
                <div className="portfolio-item mx-auto" data-toggle="modal" data-target="#portfolioModal5">
                    {/* <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                    <div className="portfolio-item-caption-content text-center text-white">
                        <i className="fas fa-plus fa-3x"></i>
                        <p>Learn everything you need about django</p>
                    </div>
                    </div> */}
                    <img className="img-fluid" src={process.env.PUBLIC_URL+"img/understanding_django.png"} alt=""/>
                    
                </div>
                </div>

               
                <div className="col-md-6 col-lg-4">
                <div className="portfolio-item mx-auto" data-toggle="modal" data-target="#portfolioModal6">
                    {/* <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                    <div className="portfolio-item-caption-content text-center text-white">
                        <i className="fas fa-plus fa-3x"></i>
                        <p> LEARN PYTHON FROM SCRATCH</p>
                    </div>
                    </div> */}
                    <Link to='/learn-python'>
                      <img className="img-fluid" src={process.env.PUBLIC_URL+"img/gpython.jpg"} alt=""/>
                    </Link>
                </div>
                </div>

            </div>
            </div>
        </section>

        <section className="page-section portfolio" id="tutorials">
            <div className="container">

            <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">TUTORIALS</h2>

            <div className="divider-custom">
                <div className="divider-custom-line"></div>
            </div>
 
            <div className="row">
              {/*
                <div className="col-md-6 col-lg-4">
                <div className="portfolio-item mx-auto" data-toggle="modal" data-target="#portfolioModal1">
                    <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                    <div className="portfolio-item-caption-content text-center text-white">
                        <i className="fas fa-plus fa-3x"></i>
                    </div>
                    </div>
                    <img className="img-fluid" src="img/portfolio/cabin.png" alt=""/>
                </div>
                </div>

                
                 <div className="col-md-6 col-lg-4">
                <div className="portfolio-item mx-auto" data-toggle="modal" data-target="#portfolioModal2">
                    <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                    <div className="portfolio-item-caption-content text-center text-white">
                        <i className="fas fa-plus fa-3x"></i>
                    </div>
                    </div>
                    <img className="img-fluid" src="img/portfolio/cake.png" alt=""/>
                </div>
                </div>

                
                <div className="col-md-6 col-lg-4">
                <div className="portfolio-item mx-auto" data-toggle="modal" data-target="#portfolioModal3">
                    <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                    <div className="portfolio-item-caption-content text-center text-white">
                        <i className="fas fa-plus fa-3x"></i>
                    </div>
                    </div>
                    <img className="img-fluid" src="img/portfolio/circus.png" alt=""/>
                </div>
                </div> */}

                
                <div className="col-md-6 col-lg-4">
                <div className="portfolio-item mx-auto" data-toggle="modal" data-target="#portfolioModal4">
                    {/* <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                    <div className="portfolio-item-caption-content text-center text-white">
                        <i className="fas fa-plus fa-3x"></i>
                    </div>
                    </div> 
                    <img className="img-fluid" src={process.env.PUBLIC_URL+"img/reactjs.png"} alt=""/>*/}
                    <p>With in a week my react tutorials will be out</p>
                </div>
                </div>

                
                <div className="col-md-6 col-lg-4">
                <div className="portfolio-item mx-auto" data-toggle="modal" data-target="#portfolioModal5">
                    {/* <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                    <div className="portfolio-item-caption-content text-center text-white">
                        <i className="fas fa-plus fa-3x"></i>
                        <p>Learn everything you need about django</p>
                    </div>
                    </div> 
                    <img className="img-fluid" src={process.env.PUBLIC_URL+"img/understanding_django.png"} alt=""/>*/}
                    <p>Django tutorials are going to be released next month </p>
                    
                </div>
                </div>

               
                <div className="col-md-6 col-lg-4">
                <div className="portfolio-item mx-auto" data-toggle="modal" data-target="#portfolioModal6">
                    {/* <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                    <div className="portfolio-item-caption-content text-center text-white">
                        <i className="fas fa-plus fa-3x"></i>
                        <p> LEARN PYTHON FROM SCRATCH</p>
                    </div>
                    </div> 
                    <img className="img-fluid" src={process.env.PUBLIC_URL+"img/gpython.jpg"} alt=""/>*/}
                    <p>Python tutorials </p>
                </div>
                </div>

            </div>
            </div>
        </section>

        <section className="page-section bg-secondary text-white mb-0" id="about">

        <h2 className="page-section-heading text-center text-uppercase text-white">About</h2>

            <div className="divider-custom divider-light">
              <div className="divider-custom-line"></div>
            </div>
        <div className="container d-flex align-items-center flex-column">
            <img className="image-container align-items-center" src={process.env.PUBLIC_URL+"img/myPic.jpg"}
            style={{
              width: 350,
              height: 300,
              borderRadius: '50%',
              overflow: 'hidden',
              borderWidth: 3,
              borderColor: 'red',

            }} alt="" />      
            </div>
            <div className="container">

            {/* <img class="masthead-avatar mb-5" src="%PUBLIC_URL%/myPic.jpg" alt=""/> */}

              <div className="row">
                <div className="col-lg-4 ml-auto">
                  <p className="lead">I'm Mukasa Willy. I have been programming since 2012 in various languages</p>
                </div>
                <div className="col-lg-4 mr-auto">
                  <p className="lead">I can help others learn what I've learnt.I have deceided to focus on Python and its CMS (django and flask) and React for frontend</p>
                </div>
              </div>

              
              <div className="text-center mt-4">
                <a className="btn btn-xl btn-outline-light" href="#">
                  <i className="fa fa-download mr-2"></i>
                    Download my Resume!
                </a>
              </div>

            </div>
        </section>

        <section className="page-section ">
            <div className="container">

            <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">Need Help?</h2>
            <div className="divider-custom">
                <div className="divider-custom-line"></div>
            </div>
            <div className="container d-flex align-items-center flex-column">
            <p>For any assistance</p><a href="#">CLICK HERE TO LEARN MORE</a>
           
          </div>
            </div>
        </section>
        <Footer/>
        </div>
     );
}
 
export default home;