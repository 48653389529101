const pythonTutorials =[
    {
        '_id': '2201',
        'title': 'Getting Started with Python Programming',
        'section':'',
        'type':'',
        'video': '/videos/Intro.mkv',
        'description':
          'What is Python and Installing Python',
          'summary':'Introduction to python',
          'time': '4:40',
      },
      {
        '_id': '2202',
        'title': 'Getting Started with Python Programming',
        'section':'',
        'type':'',
        'video': '/videos/HelloWorld.mkv',
        'description':
          'Developing Hello,world Programe',
        'summary':'Testing and writting our first programe',
        'time': '14:02',

      },
      {
        '_id': '2203',
        'title': 'Python Basics',
        'section':'Section1',
        'type':'Fundamentals',
        'video': '/videos/Python_ Syntax.mkv',
        'description':'Python Syntax',
        'summary':'introduce you to the basic Python programming syntax',
        'time': '15:30',

      },
      {
        '_id': '2204',
        'title': 'Python Basics',
        'section':'Section1',
        'type':'Fundamentals',
        'video': '/videos/Variables.mkv',
        'description': 'Variables',
        'summary':'explain to you what variables are and how to create concise and meaningful variables.',
        'time': '03:40',

      },
      {
        '_id': '2205',
        'title': 'Python Basics',
        'section':'Section1',
        'type':'Fundamentals',
        'video': '',
        'description':  'Strings',
        'summary':'learn about the string data and some basic string operations.',
        'time': '03:40',

      },
      {
        '_id': '2206',
        'title': 'Python Basics',
        'section':'Section1',
        'type':'Fundamentals',
        'video': '/videos/Python_Numbers.mkv',
        'description':
          'Python Numbers',
        'summary':'introduce to you the commonly-used number types including integers and floating-point numbers.',
        'time': '11:30',

      },
      {
        '_id': '2207',
        'title': 'Python Basics',
        'section':'Section1',
        'type':'Fundamentals',
        'video': '/videos/PythonBoolean.mkv',
        'description':
          'Python Boolean',
        'summary':'explain the Boolean data type, falsy and truthy values in Python.',
        'time': '06:53',

      },
      {
        '_id': '2208',
        'title': 'Python Basics',
        'section':'Section1',
        'type':'Fundamentals',
        'video': '',
        'description':
          'Constants',
        'summary':'show you how to define constants in Python.',
        'time': '06:53',

      },
      {
        '_id': '2209',
        'title': 'Python Basics',
        'section':'Section1',
        'type':'Fundamentals',
        'video': '/videos/PythonBoolean.mkv',
        'description':
          'Comments',
        'summary':'learn how to make notes in your code.',
        'time': '06:53',

      },
      {
        '_id': '2210',
        'title': 'Python Basics',
        'section':'Section1.',
        'type':'Fundamentals',
        'video': '',
        'description':
          'Type conversion',
      'summary':'learn how to convert a value of one type to another e.g., converting a string to a number.',
        'time': '06:53',

      },


]
export default pythonTutorials