import { Link } from 'react-router-dom'
const CourseList = ({pythonTutorials}) => {
    return ( 
        <div>
            {pythonTutorials.map((course) =>( 
            <ul className='section-list' key={course.id}>
            <li className='section-item'>
                  <Link
                    className='item'
                    data-ss-course-id='302571'
                    data-ss-event-name='Lecture: Start (Sales - PE v1)'
                    data-ss-event-href='/courses/understanding-django/lectures/5601751'
                    data-ss-event-type='button'
                    data-ss-lecture-id='5601751'
                    data-ss-school-id='70292'
                    data-ss-user-id=''
                    // href='/courses/understanding-django/lectures/5601751'
                    to={`/python-begineers/${course._id}`}
                  >
                    <span className='lecture-icon'>
                      <i className='fa fa-youtube-play'></i>
                    </span>
                    
                      <div className='btn-danger btn-sm pull-right lecture-start'>
                        Start
                        </div>
                        {course.description}
                      ({course.time}) 
                  </Link>
                </li>
            </ul>    
          ))}
        </div>
     );
}
 
export default CourseList;