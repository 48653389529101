import {React,useEffect} from 'react'
import {Helmet} from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
function Footer() {
    // useEffect(() => {
    //     const script = document.createElement('script');
      
    //     <script src="vendor/jquery/jquery.min.js"></script>
    //     script.async = true;
      
    //     document.body.appendChild(script);
      
    //     return () => {
    //       document.body.removeChild(script);
    //     }
    //   }, []);
  return (
    <div>
          <footer className="footer text-center bg-dark ">
                <div className="container ">
                <div className="row">
                    <div class="col-lg-4 mb-5 mb-lg-0">    
                    </div>
                    <div className="col-lg-4 mb-5 mb-lg-0">
                    <h4 className="text-uppercase mb-4">Around the Web</h4>
                    <a className="btn btn-outline-light btn-social mx-1" href="#">
                         <i className="fa fa-facebook"></i>  
                    </a>
                    <a className="btn btn-outline-light btn-social mx-1" href="#">
                        <i className="fa fa-twitter"></i>
                    </a>
                    <a className="btn btn-outline-light btn-social mx-1" href="#">
                        <i className="fa fa-linkedin"></i>
                    </a>
                    <a className="btn btn-outline-light btn-social mx-1" href="#">
                        <i className="fa fa-dribbble"></i>
                    </a>
                    </div>

                </div>
                </div>
            </footer>

            <section className="copyright py-4 text-center text-white ">
                <div className="container">
                <small>Copyright &copy; haffyfashions.com 2022</small>
                </div>
            </section>

            <div className="scroll-to-top d-lg-none position-fixed ">
                <a className="js-scroll-trigger d-block text-center text-white rounded" href="#page-top">
                <i className="fa fa-chevron-up"></i>
                </a>
            </div>
            <Helmet>
            <script src="vendor/jquery/jquery.min.js"></script>
            <script src="vendor/bootstrap/js/bootstrap.bundle.min.js"></script>

            <script src="vendor/jquery-easing/jquery.easing.min.js"></script>
            <script src="js/jqBootstrapValidation.js"></script>
            <script src="js/contact_me.js"></script>
            <script src="js/freelancer.min.js"></script> 
            </Helmet>
    </div>
  )
}

export default Footer