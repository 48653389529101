import SubHeader from '../components/SubHeader';
import Footer from '../components/Footer';
import { useState,useEffect } from 'react';
import CourseList from '../components/CourseList';
import pythonTutorials from '../pythonTutorials'
//import {Link} from 'react-router-dom'
const PythonDetails = () => {
  useEffect(()=>{
    //console.log(courses)

  });
    return ( 
      <div id="page-top"> 
      
        <SubHeader/>
          <header className="masthead bg-secondary text-white text-center">
          <div className="container d-flex align-items-center flex-column">
            <div class="row">
              <div class="col-sm-6">
                <img className="img-fluid" src={process.env.PUBLIC_URL+"img/gpython.jpg"} alt=""/>
              </div>
              <div class="col-sm-6">
                  <h6 class="masthead-heading text-lowercase mb-0">PYTHON FROM BEGINNER TO ADVANCED</h6><br/>
                  <button type="button" class="btn btn-danger btn-sm">Enroll in Course</button> 
              </div>
            </div>
          </div>
        </header>
        <section className="breadcrumb">  
            <div className="container container d-flex align-items-center">
            <div className="row">
              <div className="col-lg-2"></div>
            <div className="col-lg-8 ">
              <p>
                <strong>In this course you learn comprehensive overview of Python.</strong> 
              You will start by learning  the foundation and  learning the introductory topics
               like operators, conditional statements, functions,loops, assigment statements, and more
               You will then move onto more advanced topics such as classes and inheritance, iterators,
               list comprehensions, and dictionaries.
               </p>
               <p>
                 <strong>Why python?</strong>
                <li><strong>Easy to Learn and Use</strong></li>
                 Its credibly easy to use and learn. The python language is one of the most accessible programming
                languages available because of its simplified syntax not complicated, it gives you more emphasis on natural language 
                For that reason python language can easily be written and executed much faster than other programming languages
                <li><strong>Automation</strong></li>
                There is alot of Automation of tasks as there are lots of tools and modules available, which makes things much more comfortable
               </p>
               <p>For this course we shall learn python for functions of<strong> Web development, Embedded system development(AI) and Programming Applications</strong> like:
                 <li><strong>Read and create file dictionaries</strong></li>
                 <li><strong>Create GUIs and APIs</strong></li>
               </p>
               <p>
                 Once you enroll, you'll get access to several hours of content that you can work through at your own pace.
                 <strong>Each video includes the code from the video</strong>, which makes it much easier for you to follow along. You can protect your investment by downloading the course videos and code. Once you purchase the course,
                 <strong>feel free to download all the videos and code in the course to add to your personal library.</strong> 
               </p>
               <p>
                 <strong>
                 I'm always available to answer questions. Each video has its own comment section that I monitor. If you want proof of my willingness to respond, check any of my YouTube videos. I respond to every comment!
                 </strong>
               </p>
            </div>
            <div className="col-lg-2"></div>
          </div>
          </div>
        </section>
        <section className="page-section ">
            <div className="container d-flex align-items-center flex-column">
              <p>Join  the course today by clicking the "Enroll in Course" button.I'll see you inside</p>
            </div>
        </section>
        <section className="breadcrumb">
            <div className="container d-flex align-items-center flex-column">
            <div className="row">
              <div className="col-lg-2">

              </div>
            <div class="col-lg-2">
                <img className="image-container align-items-center" src={process.env.PUBLIC_URL+"img/myPic.jpg"}
                style={{
                  width: 150,
                  height: 150,
                  borderRadius: '50%',
                  overflow: 'hidden',
                  borderWidth: 3,
                  borderColor: 'red',

                }} alt="" />   
              </div>
            <div class="col-lg-6">
                <p>I'm Mukasa Willy. I have been programming since 2012 in various languages.
                   It hard to put everything together  while searching the internet, that's why 
                   i have put up simple and straight forward Python coaching </p>
              </div>
            </div>
            <div className="col-lg-2"></div>
            </div>
        </section>

        <div className="course-block block curriculum  even-stripe" id="block-5757994">     
      <div className='container'>
        <div className='row'>
        <div className='col-lg-2'></div>
          <div className='col-xs-10 col-xs-offset-1 col-md-8 col-md-offset-2'>
            <h2>
              Course Curriculum
            </h2>
            <br/>      
      <div className='row'>
            <div  className='col-sm-12 course-section' >
              <div className='section-title' data-release-date="" data-days-until-dripped=""
                   data-is-dripped-by-date="" data-course-id="302571">
            <span className="section-lock">
              <i className="fa fa-lock"></i>&nbsp;
            </span>
                Getting Started with Python Programming
            <div className="section-days-to-drip">
              <div className="section-days-logged-in">
                Available in
                <span className="section-days-to-drip-number"></span>
                days
              </div>
                <div className="section-days-logged-out">
                  <span className="section-days-to-drip-number"></span>
                  days
                  after you enroll
                </div>
              </div>
            </div>
                <CourseList pythonTutorials={pythonTutorials.filter((course)=> course.title ==='Getting Started with Python Programming')}/>
          </div>
          </div>
          <div className='row'>
        <div className='col-sm-12 course-section'>
          <div className='section-title' data-release-date="" data-days-until-dripped="" data-is-dripped-by-date="" data-course-id="302571">
            <span className="section-lock">
              <i className="fa fa-lock"></i>&nbsp;
            </span>
                 Python Basics&nbsp;<strong>Section1</strong>   
            <div className="section-days-to-drip">
              <div className="section-days-logged-in">
                Available in
                <span className="section-days-to-drip-number"></span>
                days
              </div>
                <div className="section-days-logged-out">
                  <span className="section-days-to-drip-number"></span>
                  days
                  after you enroll
                </div>
              </div>
            </div>
            <CourseList pythonTutorials={pythonTutorials.filter(
                    (course)=> course.title ==='Python Basics' && course.section ==='Section1')}/>
            </div>
          </div> 
        </div>
        </div>
      </div>
      </div>
      <Footer/>
    </div>
      
    );
}
 
export default PythonDetails;