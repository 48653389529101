import { Link } from 'react-router-dom'
import pythonTutorials from '../pythonTutorials'
const  VideoCouList= ({pythonTutorials}) => {
    return (  
        <ul className="app-menu">
            {pythonTutorials.map((cou) =>( 
            <li  key={cou._id}>
                <a class="app-menu__item" href={`/python-begineers/${cou._id}`}>
                <span className='lecture-icon'>
                <i className='fa fa-youtube-play'></i>
                </span>&nbsp;<span class="app-menu__label">{cou.description}&nbsp;({cou.time})</span>
                </a>                      
            </li> 
            ))}
     </ul>
    );
}
 
export default VideoCouList;