import React from 'react'
import {Link,useParams} from 'react-router-dom'
import '../dashboard.css';
import reactTutorials from '../reactTutorials'
import { Player } from 'video-react';
import {Helmet} from "react-helmet";
import React_VideoCouList from '../components/React_VideoCouList';

export default function React_VideoAdmin() {
  const { id } = useParams(); 
  const course = reactTutorials.find((c)=> c._id === (id))
  return (
    <body className="app sidebar-mini">
    <header className="app-header"><a className="app-header__logo" href="/">Home</a>      
        <link
          rel="stylesheet"
          href="https://video-react.github.io/assets/video-react.css"    
        />
        <a className="app-sidebar__toggle" href="#" data-toggle="sidebar" aria-label="Hide Sidebar"></a>
    </header>

    <div className="app-sidebar__overlay" data-toggle="sidebar"></div>
    <aside className="app-sidebar ">
      <div className="app-sidebar__user">   
        <h1 class="app-sidebar__user-name" >React</h1>  
      </div>
  
        <ul className="app-menu">
            <li><h3 className="app-menu__item active">
                    <span className="app-menu__label">Introduction</span></h3></li>
            <li>
               <React_VideoCouList reactTutorials={reactTutorials.filter((cou)=> cou.description ==='Introduction')}/></li>
{/*         
              <li><h3 className="app-menu__item active">
                    <span className="app-menu__label">Python Basics&nbsp;&nbsp;<strong>Section1.Fundamentals</strong></span></h3></li>
            <li><VideoCouList pythonTutorials={pythonTutorials.filter
                      ((cou)=> cou.title ==='Python Basics'  && cou.section ==='Section1')}/></li> */}
        </ul>    
    </aside>

    <main className="app-content">
      {/* <div className="app-title">

        <ul className="app-breadcrumb breadcrumb">
          <li className="breadcrumb-item"><i className="fa fa-home fa-lg"></i></li>
          <li className="breadcrumb-item"><Link to={`/`}>Home</Link></li>
        </ul>
      </div> */}
 <div className="row1" >
        <div className="col-md-12">
          <div className="tile">
            <h3 className="tile-title">{course.title}</h3>
            <div className="post-content">
              {/* <Player>
                  <source src={course.video} />
              </Player> */}
              </div>
          </div>
        </div>
        {/* <div class="col-md-12">
          <div class="tile">
            <h3 class="tile-title">Simple Table</h3>
            <table class="table">
              <thead>
                <tr>

                </tr>
              </thead>
              <tbody>
                <tr>
                <Player>
                  <source src={course.video} />
              </Player>

                </tr>

              </tbody>
            </table>
          </div>
        </div> */}

        <div className="col-md-12">
          <div className="tab-content">
            <div className="tab-pane active" id="user-timeline">
              <div className="timeline-post">
                <div className="post-media">

                </div>
                <div className="post-content">
                  <Player>
                  <source src={course.video} />
                  </Player>
                </div>

              </div>

            </div>

          </div>
        </div>


        </div>
    </main>
      <Helmet>
          <script src="js/jquery-3.3.1.min.js"></script>
          <script src="js/popper.min.js"></script>
          <script src="js/bootstrap.min.js"></script>
          <script src="js/main.js"></script>
          <script src="js/plugins/pace.min.js"></script>
      </Helmet>
    </body>
  )
}
