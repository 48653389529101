const reactTutorials =[
    {
        '_id': '2201',
        'title': 'Introduction to React',
        'video': '/videos/react_intro.mkv',
        'description':
          'Introduction',
          'summary':'Introduction to python',
          'time': '4:03',
      },
      {
        '_id': '2202',
        'title': 'Creating React App',
        'video': '/videos/Creating_ react_App.mkv',
        'description':
          'Introduction',
        'summary':'Creating react app',
        'time': '17:07',

      }


]
export default reactTutorials